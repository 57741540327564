import { ref } from "vue"
import { getFirestore, doc, getDoc } from "firebase/firestore"

export function useProgram() {
    const program = ref(null)
    const loading = ref(false)
    const error = ref(null)

    const fetchProgram = async () => {
        const db = getFirestore()
        const docRef = doc(db, "programs/ramiro")
        loading.value = true

        try {
            const docSnap = await getDoc(docRef)
            if (docSnap.exists()) {
                program.value = docSnap.data()
            } else {
                throw new Error("Program not found")
            }
        } catch (err) {
            error.value = err.message
        } finally {
            loading.value = false
        }
    }

    return {
        program,
        loading,
        error,
        fetchProgram,
    }
}
