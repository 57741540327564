import { ref } from 'vue'
import { signInWithEmailAndPassword, signOut } from 'firebase/auth'
import { auth } from '@/firebase'
import { doc, getDoc } from 'firebase/firestore'
import { db } from '@/firebase'

export function useAuth() {
    const errorMessage = ref('')

    const handleLogin = async (email, password) => {
        try {
            const result = await signInWithEmailAndPassword(auth, email, password)
            const user = result.user

            // Validate user access
            const accessGranted = await validateAccess(user.uid)
            if (!accessGranted) {
                await signOut(auth) // Log out the user if validation fails
                return null
            }



            return { authUser: user }
        } catch (error) {
            console.error('Login error:', error.message)
            errorMessage.value = 'Invalid email or password.'
            return null
        }
    }

    const validateAccess = async (uid) => {
        try {
            //TODO change to program instead of hardcoded
            const userDocRef = doc(db, 'users/ramiro/users', uid)
            const userDoc = await getDoc(userDocRef)

            if (!userDoc.exists()) {
                console.warn('No Firestore user data found for UID.')
                return false
            }

            const userData = userDoc.data()

            if (!userData.active) {
                console.warn('Account is inactive.')
                return false
            }
            //TODO change to program instead of hardcoded
            if (userData.role !== 'admin' && userData.nick !== 'ramiro') {
                console.warn('Access denied for non-admin user.')
                return false
            }

            return true
        } catch (error) {
            console.error('Validation error:', error.message)
            return false
        }
    }



    return {
        handleLogin,
        validateAccess,
        errorMessage
    }
}
