<template>
  <template v-if="program && !program.active">
    <div class="inactive-screen flex justify-content-center align-items-center">
      <p class="program-inactive-message text-red-500 text-center">
        Esta aplicação se encontra inativa.
      </p>
    </div>
  </template>
  <div class="page-container" v-if="program && program.active">
    <div class="login-container surface-card border-round-lg shadow-2 flex flex-column align-items-center py-5 my-8 px-4 mx-auto">
      <img :src="logo" alt="Logo" class="main-logo mb-4">
      <form @submit.prevent="login" class="w-full">
        <div class="input-group flex flex-column mb-3 w-full">
          <label for="email" class="mb-1 font-bold">Email</label>
          <YInputText
              id="email"
              v-model="form.email"
              type="email"
              placeholder="Digite o seu e-mail"
              class="w-full p-inputtext-lg"
              required
          />
        </div>
        <div class="input-group flex flex-column mb-3 w-full">
          <label for="password" class="mb-1 font-bold">Password</label>
          <YPassword
              id="password"
              v-model="form.password"
              :feedback="false"
              placeholder="Digite a sua password"
              toggleMask
              class="w-full p-inputtext-lg"
              required
          />
        </div>
        <div class="flex justify-content-center w-full">
          <YButton type="submit" class="p-button-lg w-full justify-content-center">Login</YButton>
        </div>
      </form>
      <p v-if="errorMessage" class="error-message mt-3 text-red-500">{{ errorMessage }}</p>
    </div>
  </div>
  <FooterComponent :program-data="program" />
</template>

<style scoped lang="sass">
.page-container
  display: flex
  height: 100%
  flex-direction: column
  align-items: center
  padding: 16px

  .login-container
    display: flex
    flex-direction: column
    justify-content: center
    width: 100%
    max-width: 450px
    margin: 0 auto
    padding: 2rem

    @media screen and (max-width: 768px)
      padding: 1.5rem
      margin: 1rem auto

    @media screen and (max-height: 600px)
      padding: 1rem
      margin: 0.5rem auto

    .main-logo
      max-width: 200px
      height: auto
      margin-bottom: 2rem

      @media screen and (max-height: 600px)
        max-width: 150px
        margin-bottom: 1rem

    .input-group
      margin-bottom: 1.5rem

      @media screen and (max-height: 600px)
        margin-bottom: 1rem

      label
        margin-bottom: 0.5rem

    .p-button-lg
      @media screen and (max-height: 600px)
        padding: 0.75rem !important

.inactive-screen
  height: 100vh
  width: 100%

.input-group
  margin-bottom: 1rem
  text-align: left

label
  display: block
  margin-bottom: 0.5rem
  font-weight: bold

button:hover
  background-color: #0056b3

.error-message
  margin-top: 1rem
  color: red
  font-size: 0.9rem
</style>

<script setup>
import { reactive, onMounted } from 'vue'
import { useAuth } from '@/composables/useAuth'
import { useProgram } from '@/composables/useProgram'
import FooterComponent from './footerComponent.vue'
import logo from '@/assets/logo-vertical.svg'

  const { handleLogin, errorMessage } = useAuth()
  const { program, fetchProgram } = useProgram()

  const form = reactive({
    email: '',
    password: ''
  })

  const login = async () => {
    if (!form.email || !form.password) {
      errorMessage.value = 'Email and password are required'
      return
    }

    const result = await handleLogin(form.email, form.password)

    if (!result) {
      alert('Não tem acesso a esta aplicação')
    }
  }

  onMounted(async () => {
    await fetchProgram()
  })
</script>
