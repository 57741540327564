import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp)
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp)
export default firebaseApp;
